"use strict";

export default {
  tmsLink: "http://hypercubes.io/",
  tmsSidebarLogo: "logo-sidebar.png",
  tmsLogo: "hyper-logo.png",
  tmsName: "Revenova",
  tmsLoginMessage: "Truck Capacity Assistant",
  tmsRequiredEntities: [
    "hu_count",
    "zip_to",
    "zip_from",
    "dimensions",
    "weight"
    // "date_available",
    // "equipment_description",
    // "number_available",
    // "origin_country_code",
    // "origin_state_province_code",
    // "origin_city",
    // "contact_email"
  ],
  tmsFontColor: "#811c0e"
};
