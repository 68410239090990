<template>
  <footer class="footer">
    <p>
      v1.5.6 - Copyright &copy; {{ year }} | developed by:
      <a href="http://guane.com.co/" target="_blank">guane Enterprises</a>
      - Contact information:
      <a href="mailto://charlie@guane.com.co"> charlie@guane.com.co</a>
    </p>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data: function() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--footer-bg-color);
  color: #fff;
  width: 100%;
  height: 80px;

  a,
  a:hover {
    color: #ffffff;
    text-decoration: underline;
  }
}
</style>
