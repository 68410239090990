<template>
  <span @click="goTo"><slot></slot></span>
</template>

<script>
export default {
  name: "SidebarLink",
  props: {
    to: String,
    type: String
  },
  methods: {
    goTo: function() {
      if (this.$route.path === this.to)
        this.$router.go({ name: this.to, params: { type: this.type } });
      else this.$router.push({ path: this.to, params: { type: this.type } });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
span {
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
</style>
