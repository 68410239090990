import axios from "axios";

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}/api`;

export const createAxios = (token) => {
  return axios.create({
    baseURL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      // "content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  });
};

export default createAxios;
