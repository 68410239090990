<template>
  <div class="app-content">
    <template v-if="!withoutNav()">
      <TheSidebar
        @mouseover.native="toggleSidebar"
        @mouseout.native="toggleSidebar"
      />
    </template>

    <div
      :class="{ withoutNav: withoutNav() }"
      class="view-wrapper side-content"
    >
      <TheNavbar v-if="!withoutNav()" />
      <router-view class="main-content" :key="$route.path" />
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheSidebar from "@/components/TheSidebar/TheSidebar";
import TheNavbar from "@/components/TheNavbar";
import TheFooter from "@/components/TheFooter";
import { mapGetters } from "vuex";

export default {
  name: "AppContent",
  components: {
    TheSidebar,
    TheNavbar,
    TheFooter
  },
  computed: {
    ...mapGetters("user", ["isAuth"])
  },
  data: function() {
    return {
      isLoading: true
    };
  },
  methods: {
    withoutNav: function() {
      return (
        // !this.isAuth ||
        this.$route.name === "hardfail" ||
        this.$route.name === "notFound" ||
        this.$route.name === "login"
      );
    },
    toggleSidebar: function() {
      const toSlide = document.querySelector(".side-content");
      const navbar = document.querySelector(".the-navbar");
      toSlide.classList.toggle("content-collapsed");
      navbar.classList.toggle("navbar-collapsed");
    }
  }
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background-color: var(--button-hover-color);
  color: #fff;
}

.the-navbar {
  width: calc(100% - 60px);
  position: fixed;
  z-index: 1;

  &.navbar-collapsed {
    width: calc(100% - 220px);
  }
}

.side-content {
  width: 90%;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background-color: var(--app-bg-color);
  transition: all 0.4s;
  margin-left: 60px;

  &.withoutNav {
    margin-left: 0;
    transition: none;

    .main-content {
      margin-top: 0;
    }
  }

  .main-content {
    flex-grow: 1;
    position: relative;
    margin-top: 50px;
    padding-bottom: 20px;
  }
}

.content-collapsed {
  margin-left: 220px;

  & > .quote-content {
    width: calc(100% - 30px);
  }
}

@media (max-width: 400px) {
  .footer {
    padding: 15px;
    font-size: 0.8em;
  }
}
</style>
