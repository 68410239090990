"use strict";

// ****************************************************************
// Arrays Methods
// ****************************************************************

export const unique = function(array) {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export const hasNotEmptyValues = function(data) {
  let flag = true;
  for (let index in data) {
    if (data[index] === "") flag = false;
  }
  return flag;
};

export const validArrays = function(huCountArray, dimensionsArray) {
  return (
    hasNotEmptyValues(huCountArray) &&
    hasNotEmptyValues(dimensionsArray) &&
    huCountArray.length === dimensionsArray.length
  );
};

// ****************************************************************
// Objects Methods
// ****************************************************************

export const filterObject = function(object, filter) {
  const newObject = {};
  filter.forEach(function(key) {
    newObject[key] = object[key];
  });
  return newObject;
};

// ****************************************************************
// Other Methods
// ****************************************************************

export const removeEOL = (string) => {
  if (string !== "") string = string.replace(/(\r\n|\n|\r)/gm, "");
  return string;
};

export const isValidOperation = (op) => {
  for (let i = 0; i < op.length; i++) {
    let char = op.charCodeAt(i);
    if (char !== 32 && (char < 40 || char > 57)) {
      return false;
    }
  }
  return true;
};

export const processInputValue = (value) => {
  let op = value
    .match(/[^a-z]/gi, "")
    .join("")
    .trim();
  let units = value.replace(/[^a-z]/gi, "");
  if (!isValidOperation(op)) return value;
  else return `${calculator(op)} ${units}`;
};

export const pixelToInt = (pixel) => {
  if (pixel) return parseInt(pixel.match(/[a-z]+|[^a-z]+/gi)[0]);
  else return null;
};

export const calculator = (operation) => {
  let result = "1";
  try {
    let res = eval(operation);
    if (!isNaN(res) && res !== Infinity && res !== null)
      result = Math.ceil(res).toString();
    return result;
  } catch (err) {
    return result;
  }
};

import Swal from "sweetalert2";
export const showAlert = function({
  alertType = "error",
  title = "Error",
  text = "",
  html = "",
  confirmText = "Ok",
  cancel = false
}) {
  return Swal.fire({
    title,
    text,
    html,
    icon: alertType,
    showCancelButton: cancel,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmText
  });
};

// export const getStatusType = status => {
//   let type;
//   switch (status) {
//     case 1:
//       type = "noLtl";
//       break;
//     case 2:
//       type = "ner";
//       break;
//     case 3:
//       type = "inProcess";
//       break;
//     case 4:
//       type = "failed";
//       break;
//     case 6:
//       type = "sent";
//       break;
//     case 9:
//       type = "deleted";
//       break;
//     // case 6:
//     //   type = "hardfail";
//     //   break;
//     // case 9:
//     //   type = "webhookFail";
//     //   break;
//   }
//   return type;
// };

// export const getStatusByType = type => {
//   let status;
//   switch (type) {
//     case "details":
//       status = 0;
//       break;
//     case "nonLtl":
//       status = 1;
//       break;
//     case "ner":
//       status = 2;
//       break;
//     case "inProcess":
//       status = 3;
//       break;
//     case "failed":
//       status = 4;
//       break;
//     case "sent":
//       status = 6;
//       break;
//     case "deleted":
//       status = 9;
//       break;
//     // case "hardfail":
//     //   status = 6;
//     //   break;
//     // case "webhookFail":
//     // status = 9;
//     // break;
//     default:
//       status = 8;
//       break;
//   }
//   return status;
// };

// export const initParametersByType = type => {
//   let params = {};
//   switch (type) {
//     case "nonLtl":
//       params.title = "Non LTL";
//       params.dir = "desc";
//       break;
//     case "ner":
//       params.title = "LTL Queue";
//       params.dir = "asc";
//       break;
//     case "inProcess":
//       params.title = "LTL In Process";
//       params.dir = "asc";
//       break;
//     case "failed":
//       params.title = "Failed Emails";
//       params.dir = "desc";
//       break;
//     case "sent":
//       params.title = "Sent Emails";
//       params.dir = "desc";
//       break;
//     case "deleted":
//       params.title = "Deleted Emails";
//       params.dir = "desc";
//       break;
//     // case "hardfail":
//     //   params.title = "Hard Fails";
//     //   params.dir = "asc";
//     //   break;
//     // case "webhookFails":
//     //   params.title = "Webhook Fails";
//     //   params.dir = "asc";
//     //   break;
//     default:
//       params.title = "Error Loading Data";
//       params.dir = "asc";
//       break;
//   }
//   return params;
// };
