"use strict";

import API from "@/api/methods";
// Delete dead code
// import config from "@/utils/config.js";

export function login(userData) {
  return API.post("/auth/access-token", userData);
}

export const getStandardizedEntity = (entity, entityValue) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/operations/standardize_${entity}/`;
  const payload = { [entity]: entityValue };
  return API.post(resource, payload, token);
};

export const getNMFC = (row) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/operations/nmfc_calculator/";
  const payload = {
    hu_count: row.hu_count,
    dimensions: row.dimensions,
    weight: row.weight
  };
  return API.post(resource, payload, token);
};

export const getTotalWeight = (weight) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/operations/get_total_weight/";
  const payload = { weight };
  return API.post(resource, payload, token);
};

export const getTotalVolume = (dimensions, huCount) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/operations/get_total_volume/";
  const payload = { dimensions, hu_count: huCount };
  return API.post(resource, payload, token);
};

export const getTotalHuCount = (huCount) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/operations/get_total_hu_count/";
  const payload = { hu_count: huCount };
  return API.post(resource, payload, token);
};

export const getContributions = (dimensions, huCount, totalVolume) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/operations/get_contributions/";
  const payload = {
    dimensions,
    hu_count: huCount,
    total_volume: totalVolume
  };
  return API.post(resource, payload, token);
};

export const getWeightsFromContributions = (totalWeight, contributions) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/operations/get_weights_from_contributions/";
  const payload = {
    contributions,
    total_weight: totalWeight
  };
  return API.post(resource, payload, token);
};

// eslint-disable-next-line no-unused-vars
export const countEmails = (status, isGeneral) => {
  const token = localStorage.getItem("truck-capacity-token");
  // if (isGeneral) {
  //   resource = `/quote/count_by_fields/`;
  //   const payload = { is_active: status !== 9, status };
  //   return API.post(resource, payload, token);
  // } else {
  //   resource = `/quote/count?status=${status}`;
  //   return API.get(resource, token);
  // }
  const resource = "/quote/count_by_fields/";
  const payload = { is_active: status !== 9, status };
  return API.post(resource, payload, token);
};

// eslint-disable-next-line no-unused-vars
// Delete dead code
// export const getEmailList = (
//   status = 2,
//   skip = 0,
//   limit = config.emailsPerTable
// ) => {
export const getEmailList = (payload) => {
  let resource;
  const token = localStorage.getItem("truck-capacity-token");
  // Delete dead code
  // resource = `/quote?status=${status}&skip=${skip}&limit=${limit}`;
  resource = `/posting-requests`;
  return API.getWithParams(resource, payload, token);
};

export const updatePostingRequest = (payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/posting-requests/${payload?.id}`;
  return API.put(resource, payload?.body, token);
};

export const getUserStatus = function(username) {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `users/username/${username}`;
  return API.get(resource, null, token);
};

export const changeUserStatus = function() {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/manage/busy`;
  return API.put(resource, null, token);
};

export const assignEmails = (limit) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/allocate?limit=${limit}`;
  return API.get(resource, token);
};

export const assignEmailsById = (payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/manage/multiple_allocate`;
  return API.post(resource, payload, token);
};

export const unassignEmails = (emails) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/allocate/deallocate/${emails}`;
  return API.delete(resource, token);
};

export const getEmailById = (id) => {
  const token = localStorage.getItem("truck-capacity-token");
  let resource = `/posting-requests/${id}`;
  return API.get(resource, token);
};

export const getAttachments = (id) => {
  const token = localStorage.getItem("truck-capacity-token");
  let resource = `/posting-requests/${id}/attachments`;
  return API.get(resource, token);
};

export const getTruckPostingsPerRequest = (id) => {
  const token = localStorage.getItem("truck-capacity-token");
  let resource = `/posting-requests/${id}/truck-postings?skip=0&limit=50`;
  return API.get(resource, token);
};

export const getHardFailById = (id) => {
  const resource = `/quote/hardfail/${id}`;
  return API.get(resource);
};

export const sendHardFail = (payload) => {
  const id = payload.id;
  const resource = `/quote/hardfail/send/${id}`;
  return API.put(resource, payload);
};

export const deleteEmailById = (emailId) => {
  const token = localStorage.getItem("truck-capacity-token");
  // Delete dead code
  // const resource = `/quote/${emailId}`;
  const resource = `/posting-requests/${emailId}`;
  // Delete dead code
  // const payload = { status: 9 };
  return API.delete(resource, token);
  // Delete dead code
  // return API.put(resource, payload, token);
};

export const deleteEmails = (emailId) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/quote/${emailId}`;
  return API.delete(resource, token);
};

export const sendToNonLTL = (emails) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/quote/remove/${emails}`;
  return API.put(resource, token);
};

export const sendToLTL = (emailId, payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/quote/${emailId}`;
  return API.put(resource, payload, token);
};

export const sendQuote = (id, payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/quote/send_quote/${id}`;
  return API.put(resource, payload, token);
};

export const getTotalLTL = () => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/admin/total_ltl`;
  return API.get(resource, token);
};

export const getUsers = () => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/admin/get_all_user_info`;
  return API.get(resource, token);
};

export const getDailyUserQuotes = () => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/admin/get_by_date/3`;
  return API.get(resource, token);
};

export const distributeEmails = (numberOfEmails) => {
  let resource;
  let payload = null;
  const token = localStorage.getItem("truck-capacity-token");
  if (Number.isNaN(numberOfEmails)) resource = `/admin/distribute_quotes`;
  else {
    resource = `/admin/allocate`;
    payload = { limit: numberOfEmails };
  }
  return API.post(resource, payload, token);
};

export const clearEmails = (payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/admin/deallocate`;
  return API.put(resource, payload, token);
};

export const assignEmailsToUser = (payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/admin/allocate`;
  return API.post(resource, payload, token);
};

export const toggleUserStatus = (payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/admin/change_user_status`;
  return API.put(resource, payload, token);
};

// import axios from "axios";

// Delete dead code
// export function sendHauls(hauls) {
export function sendHauls(quoteId) {
  const token = localStorage.getItem("truck-capacity-token");
  // Delete dead code
  // const resource = "/revenova/";
  const resource = `/truck-posting/${quoteId}/post-in-revenova`;
  // return API.post(resource, hauls, token);
  return API.postWithoutPayload(resource, token);
}

export function createTruckPosting(payload) {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/truck-posting`;
  return API.post(resource, payload, token);
}

export function deleteTruckPosting(truckPostingId) {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/truck-posting/${truckPostingId}`;
  return API.delete(resource, token);
}
export function getImage(imageUrl) {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/bucket/files?file_name=${imageUrl}`;
  return fetch(`${process.env.VUE_APP_BASE_URL}/api${resource}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function getPostedEmails(params) {
  const token = localStorage.getItem("truck-capacity-token");
  // Delete dead code
  // const resource = `/statistics/posted-emails-info?from_date=${fromDate}&to_date=${toDate}`;
  const resource = `/statistics/posted-emails-info`;
  // Delete dead code
  // return API.get(resource, token);
  return API.getWithParams(resource, params, token);
}

export const updateTrip = (payload) => {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = `/truck-posting/${payload.truckPostingId}`;
  return API.put(resource, payload.body, token);
};

/* export function completeLocation(location) {
  const token = localStorage.getItem("truck-capacity-token");
  const resource = "/postprocessing/complete-location";
  return API.post(resource, location, token);
} */
