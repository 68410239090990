<template>
  <nav class="the-navbar shadowed">
    <!-- <div class="links-section">
      <SidebarLink to="/">
        <label class="menu-link">Home</label>
      </SidebarLink>
    </div> -->
    <div class="user-section">
      <li class="menu-link" @click="logout">
        <i class="pi pi-sign-out"></i> Logout
      </li>
    </div>
  </nav>
</template>

<script>
// import SidebarLink from "@/components/TheSidebar/SidebarLink";
import { mapActions } from "vuex";

export default {
  name: "TheNavbar",
  components: {
    // SidebarLink
  },
  computed: {
    searchInput: function() {
      return this.$el.querySelector(".searchbar");
    }
  },
  methods: {
    ...mapActions("user", ["logoutUser"]),
    logout: function() {
      this.logoutUser();
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.the-navbar {
  height: 50px;
  background-color: var(--navbar-bg-color);
  color: var(--app-font-color);
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  transition: font-size 100ms;
  transition: width 0.5s;
  z-index: 5;
  margin-bottom: 2px solid var(--app-font-color);

  .user-section {
    display: flex;
    margin-bottom: 2px solid var(--app-font-color);
  }

  .menu-link {
    padding: 13px 20px;
    user-select: none;

    &:hover {
      cursor: pointer;
      background-color: var(--sidebar-bg-color);
      color: #fff;
      font-weight: bold;
    }
  }
}
</style>
