<template>
  <header class="sidebar">
    <LogoPanel :tmsLogo="tmsSidebarLogo" height="150px" :link="true" />

    <nav class="nav">
      <template v-for="item in generalItems">
        <SidebarLink :key="item.title" class="menu-link" :to="item.url">
          <font-awesome-icon class="link-icon" :icon="item.icon" />
          <label class="link-label">{{ item.title }}</label>
        </SidebarLink>
      </template>
    </nav>
  </header>
</template>

<script>
import { generalItems } from "./TheSidebarItems";
import tmsConfig from "@/utils/tmsConfig";
import LogoPanel from "@/components/LogoPanel";
import SidebarLink from "@/components/TheSidebar/SidebarLink";

export default {
  name: "TheSidebar",
  components: {
    LogoPanel,
    SidebarLink
  },
  data: function() {
    return {
      tmsLink: tmsConfig.tmsLink,
      tmsLogo: tmsConfig.tmsLogo,
      tmsSidebarLogo: tmsConfig.tmsSidebarLogo,
      generalItems
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.logo-collapsed {
  visibility: hidden;
}

.logo-expanded {
  visibility: visible;
}

.sidebar {
  position: fixed;
  height: 100vh;
  background-color: var(--sidebar-bg-color);
  text-align: justify;
  color: #ffffff;
  width: 60px;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.4s;

  .nav {
    text-decoration: none;
    height: auto;
  }

  .logo-panel {
    opacity: 0;
    text-align: center;
    margin-bottom: 80px;
    padding: 1rem 0;
    transition: 0.1s;
  }

  &:hover {
    width: 220px;
  }

  &:hover .logo-panel {
    opacity: 1;
    transition: 0.3s;
  }
}

.menu-link {
  color: #d9dee0;
  display: inline-block;
  font-size: 15px;
  text-decoration: none;
  width: 100%;
  transition: font-size 0.1s;

  &:hover {
    color: white;
    font-size: 17px;
    text-decoration: none;
  }

  .link-icon {
    font-size: 20px;
    vertical-align: middle;
    padding-bottom: 3px;
    width: 60px;
  }

  .link-label {
    display: inline-block;
    width: 100%;
    padding: 20px 0 10px 20px;
    cursor: pointer;
  }
}
</style>
