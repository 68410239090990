"use strict";

import * as api from "@/api/services";

export default {
  namespaced: true,
  state: {
    totalLTL: 0,
    lastDate: "",
    users: []
  },
  actions: {
    getTotalLTL: async function({ commit }) {
      try {
        const totalLTL = await api.getTotalLTL();
        commit("setTotalLTL", totalLTL.data.total);
        commit("setLastDate", totalLTL.data["last_date"]);
      } catch (err) {
        console.error(err);
      }
    },
    getUsers: async function({ commit }) {
      try {
        const usersData = await api.getUsers();
        commit("setUsers", usersData.data);
      } catch (err) {
        console.error(err);
      }
    },
    distributeEmails: async function({ dispatch }, payload) {
      await api.distributeEmails(payload);
      dispatch("updateData");
    },
    assignEmails: async function({ dispatch }, payload) {
      api.assignEmailsToUser(payload);
      dispatch("updateData");
    },
    clearEmails: async function({ dispatch }, payload) {
      await api.clearEmails(payload);
      dispatch("updateData");
    },
    updateData: async function({ dispatch }) {
      dispatch("getTotalLTL");
      dispatch("getUsers");
    }
  },
  mutations: {
    setTotalLTL: function(state, totalLTL) {
      state.totalLTL = totalLTL;
    },
    setLastDate: function(state, lastDate) {
      state.lastDate = lastDate;
    },
    setUsers: function(state, users) {
      state.users = users;
    }
  }
};
