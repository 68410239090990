<template>
  <div class="logo-panel">
    <a v-if="link" :href="tmsLink" target="_blank">
      <img
        class="tms-logo"
        :src="require('@/assets/' + tmsLogo)"
        alt="tms-logo"
        :height="height"
      />
    </a>
    <span v-else>
      <img
        class="tms-logo"
        :src="require('@/assets/' + tmsLogo)"
        alt="tms-logo"
        :height="height"
      />
    </span>
  </div>
</template>

<script>
import tmsConfig from "@/utils/tmsConfig";

export default {
  name: "LogoPanel",
  props: {
    link: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "150px"
    },
    tmsLogo: {
      type: String,
      default: tmsConfig.tmsLogo
    },
    tmsLink: {
      type: String,
      default: tmsConfig.tmsLink
    }
  },
  mounted: function() {
    this.$el.querySelector(".tms-logo").style.width = this.width;
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
