"use strict";
import { showAlert } from "@/utils/helpers";
import { v4 as uuidv4 } from "uuid";
import config from "@/utils/config.js";
import * as api from "@/api/services";

export default {
  namespaced: true,
  state: {
    emails: [],
    emailsCount: 0,
    shownEmails: [],
    assign: false,
    sortingOrder: "asc",
    sortingColumn: ""
  },
  getters: {
    emails: (state) => state.emails
  },
  actions: {
    // Delete dead code
    getEmails: async (
      { commit, dispatch, state /* rootGetters */ },
      payload
    ) => {
      // Delete dead code
      // const status = rootGetters.params.status;
      // const limit = config.emailsPerTable;
      // const { skip } = payload;
      return api.getEmailList(payload).then(async ({ data }) => {
        if (!data?.data) data = [];
        const newEmails = await dispatch("preprocessEmails", data?.data);
        state.emailsCount = data?.count;
        const assign =
          newEmails.length <= config.minEmails && newEmails.length !== 0;
        commit("setEmails", { newEmails, assignEmails: assign });
        commit("setShownEmails", newEmails);
      });
    },
    async updatePostingRequest(_, payload) {
      try {
        const response = await api.updatePostingRequest(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          // tokenExpired();
        } else if (error?.response?.data?.detail) {
          showAlert({ html: error.response.data.detail });
        } else if (error?.response?.data?.detail[0]?.msg) {
          showAlert({ html: error.response.data.detail[0].msg });
        } else {
          showAlert({
            html:
              "Error updating truck posting. Please check the field and try again."
          });
        }
        return error;
      }
    },
    // formats date, adds id an linear feet to trips
    preprocessEmails: (context, emails) => {
      emails.forEach((email) => {
        // Delete dead code
        // Delete dead code
        // email.date_created = new Date(email.date_created).toLocaleString();
        // email.last_modified = new Date(email.last_modified).toLocaleString();
        email.created_at = new Date(email.created_at).toLocaleString();
        email.received_at = new Date(email.received_at).toLocaleString();
        email.id = email.id.toString();
        if (email.predicted_entities && email.predicted_entities.length > 0)
          email.predicted_entities.forEach(function(trip) {
            if (trip !== null && typeof trip !== "undefined") {
              trip.id = uuidv4();
              if (
                typeof trip.number_available === "undefined" ||
                trip.number_available === null ||
                trip.number_available === ""
              )
                trip.number_available = "1";
              if (
                typeof trip.contact_email === "undefined" ||
                trip.contact_email === null ||
                trip.contact_email === ""
              )
                trip.contact_email = email.client_email;
              if (
                typeof trip.destination_country_code === "undefined" ||
                trip.destination_country_code === null ||
                trip.destination_country_code === ""
              )
                trip.destination_country_code = trip.origin_country_code;
            }
          });
      });
      return emails;
    },
    deleteEmails: async ({ state, commit }, emails) => {
      let promises = [];
      const emailsArray = emails.split(",");
      emailsArray.forEach(function(emailId) {
        const promise = api.deleteEmailById(emailId);
        promises.push(promise);
      });
      return Promise.all(promises).then(() => {
        const emailIds = emails.split(",");
        const newEmails = state.emails.filter(
          (email) => !emailIds.includes(email.id)
        );
        commit("setEmails", { newEmails, assignEmails: true });
      });
    },
    deleteEmailById({ commit }, emailId) {
      commit("deleteEmail", emailId);
    },
    searchEmails: function({ state, commit }, { searchCriteria, value }) {
      const emailsToShow = state.emails.filter((row) => {
        const rowValue = row[searchCriteria].toLowerCase();
        return rowValue.indexOf(value.toLowerCase()) !== -1;
      });
      commit("setShownEmails", emailsToShow);
    },
    setSorting: function({ rootState, commit }, { column, dir = null }) {
      if (dir === null) dir = rootState.params.sortingOrder;
      commit("setSorting", { column, dir });
      commit("sortEmails");
    }
  },
  mutations: {
    setEmails: (state, { newEmails, assignEmails }) => {
      state.assign = assignEmails;
      state.emails = newEmails;
    },
    setShownEmails: function(state, emailsToShow) {
      state.shownEmails = emailsToShow;
    },
    setSorting: function(state, { column, dir }) {
      state.sortingOrder = dir;
      state.sortingColumn = column;
    },
    sortEmails: function(state) {
      state.emails.sort((a, b) => {
        const order = state.sortingOrder;
        const column = state.sortingColumn;
        if (order === "asc") return a[column] > b[column] ? 1 : -1;
        else return a[column] < b[column] ? 1 : -1;
      });
    },
    deleteEmail(state, emailId) {
      const index = state.emails.findIndex((email) => email.id === emailId);
      state.emails.splice(index, 1);
    }
  }
};
