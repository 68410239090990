"use strict";

import Vue from "vue";
import Vuex from "vuex";
// import { updateEmailList } from "@/store/plugins";
import user from "@/store/user";
import userList from "@/store/userList";
import emailList from "@/store/emailList";
import emailDetails from "@/store/emailDetails";
import statistics from "@/store/statistics";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    params: {},
    status: 0,
    isGeneral: true
  },
  modules: {
    user,
    userList,
    emailList,
    emailDetails,
    statistics
  },
  // plugins: [updateEmailList],
  getters: {
    params: function(state) {
      return state.params;
    },
    isGeneral: function(state) {
      return state.isGeneral;
    }
  },
  mutations: {
    setParams: function(state, params) {
      state.params = params;
    },
    setGeneral: (state, { isGeneral }) => {
      state.isGeneral = isGeneral;
    }
  }
});

export default store;
