<template>
  <div id="app">
    <AppContent class="app-content" />
  </div>
</template>

<script>
import AppContent from "@/views/AppContent";

export default {
  name: "app",
  components: {
    AppContent
  }
};
</script>

<style lang="scss">
@import "./utils/classes.css";
@import "./utils/params.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--app-font-family);
}

::selection {
  background-color: var(--button-hover-color);
  color: #fff;
}

#app {
  color: var(--app-font-color);
  min-height: 100vh;
}

.app-content {
  color: var(--app-font-color);
  position: relative;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  word-break: break-word;
  display: flex;
}
</style>
