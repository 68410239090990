"use strict";

import { showAlert } from "@/utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "lodash";

import * as api from "@/api/services";

export default {
  namespaced: true,
  state: {
    selectedEmail: {
      id: "",
      clientEmail: "",
      html: "",
      images: [],
      message: "",
      date: "",
      subject: "",
      probability: {},
      trips: [],
      clientEmails: [],
      emailTemplate: "",
      emailContent: "",
      isValid: true,
      newTruckPosting: null
    },
    images: []
  },
  getters: {
    selectedEmail(state) {
      return state.selectedEmail;
    },
    trips(state) {
      // Revisar map getter no esta funcionando en detailsTable.vue
      // console.log(state.selectedEmail.trips);
      return state.selectedEmail.trips;
    },
    images(state) {
      return state.images;
    }
  },
  actions: {
    getEmailById: async ({ commit, dispatch }, { id }) => {
      return api.getEmailById(id).then(async (response) => {
        const emailData = response.data;
        const processedEmail = await dispatch(
          "emailList/preprocessEmails",
          [emailData],
          { root: true }
        );
        commit("selectEmail", processedEmail[0]);
        commit("SET_IMAGES", []);
      });
    },
    getAttachments: async ({ state }, { id }) => {
      return api.getAttachments(id).then(async (response) => {
        state.selectedEmail.images = response.data.data;
      });
    },

    getTruckPostingsPerRequest({ state }, { id }) {
      return api.getTruckPostingsPerRequest(id).then(async (response) => {
        state.selectedEmail.trips = response.data.data;
        // Revisar map getter no esta funcionando en detailsTable.vue
        // console.log(state.selectedEmail.trips);
      });
    },
    // Delete dead code
    // sendHauls(ctx, payload) {
    //   return api.sendHauls(payload).then(function(res) {
    //     if (!res) throw new Error("Could not get response from server");
    //     if (!res.data)
    //       throw new Error(
    //         "Error sending hauls. Please check all the required fields or the connection with Revenova"
    //       );
    //     return res.data.join(" - ");
    //   });
    // },
    async sendHauls(_, payload) {
      try {
        const response = await api.sendHauls(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          // tokenExpired();
        } else if (error?.response?.data?.detail) {
          showAlert({ html: error.response.data.detail });
        } else if (error?.response?.data?.detail[0]?.msg) {
          showAlert({ html: error.response.data.detail[0].msg });
        } else {
          showAlert({
            html:
              "Error sending hauls. Please check all the required fields or the connection with Revenova."
          });
        }
        return error;
      }
    },
    getImage({ commit }, imageUrl) {
      return api
        .getImage(imageUrl)
        .then((response) => {
          const reader = response.body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({ done, value }) => {
                  // When no more data needs to be consumed, close the stream
                  if (done) {
                    controller.close();
                    return;
                  }
                  // Enqueue the next data chunk into our target stream
                  controller.enqueue(value);
                  return pump();
                });
              }
            }
          });
        })
        .then((stream) => new Response(stream))
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((url) => commit("ADD_IMAGE", url));
    },
    async createTruckPosting({ commit, state }) {
      try {
        commit("addTrip");
        const response = await api.createTruckPosting(state.newTruckPosting);
        state.newTruckPosting = response?.data
          ? response.data
          : state.newTruckPosting;
        state.selectedEmail.trips.push(state.newTruckPosting);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          // tokenExpired();
        } else if (error?.response?.data?.detail) {
          showAlert({ html: error.response.data.detail });
        } else if (error?.response?.data?.detail[0]?.msg) {
          showAlert({ html: error.response.data.detail[0].msg });
        } else {
          showAlert({
            html:
              "Error creating new truck. Please check all the required fields."
          });
        }
        return error;
      }
    },
    async deleteTruckPosting({ state }, index) {
      try {
        const truckPostingId = state.selectedEmail.trips[index]?.id;
        const response = await api.deleteTruckPosting(truckPostingId);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          // tokenExpired();
        } else if (error?.response?.data?.detail) {
          showAlert({ html: error.response.data.detail });
        } else if (error?.response?.data?.detail[0]?.msg) {
          showAlert({ html: error.response.data.detail[0].msg });
        } else {
          showAlert({
            html:
              "Error creating new truck. Please check all the required fields."
          });
        }
        return error;
      }
    },
    async updateTrip(_, payload) {
      try {
        const response = await api.updateTrip(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          // tokenExpired();
        } else if (error?.response?.data?.detail) {
          showAlert({ html: error.response.data.detail });
        } else if (error?.response?.data?.detail[0]?.msg) {
          showAlert({ html: error.response.data.detail[0].msg });
        } else {
          showAlert({
            html:
              "Error creating new truck. Please check all the required fields."
          });
        }
        return error;
      }
    }
  },
  mutations: {
    selectEmail: (state, email) => {
      state.selectedEmail = {
        id: email.id,
        clientEmail: email.client_email || "",
        quoteNumber: email.quote_number || "",
        // html: email.message_graph || "",
        sentHtml: email.html || "",
        // images: email.images.split(",").filter((value) => value !== "") || null,
        // message: email.model_response.message || "",
        messageState: email.message || "",
        createdDate: email.date_created || "",
        modifiedDate: email.last_modified || "",
        subject: email.subject || "",
        // probability: email.model_response.probability || {},
        // trips: email.predicted_entities || [],
        clientEmails: [],
        emailTemplate: "",
        emailContent: "",
        carrier_name: email.carrier_name,
        dot_number: email.dot_number,
        mc_number: email.mc_number
        // isValid: typeof email.model_response !== "undefined"
      };
    },
    // TRIP MUTATIONS
    // Delete dead code
    // addTrip: (state) => {
    //   let newTrip = null;
    //   if (state.selectedEmail.trips.length > 0) {
    //     const lastTripIndex = state.selectedEmail.trips.length - 1;
    //     const lastTrip = state.selectedEmail.trips[lastTripIndex];
    //     newTrip = { ...cloneDeep(lastTrip), id: uuidv4() };
    //   } else
    //     newTrip = {
    //       id: uuidv4(),
    //       number_available: "",
    //       comments: "",
    //       mc_number: "",
    //       dot_number: "",
    //       // data_source: "",
    //       origin_city: "",
    //       carrier_name: "",
    //       contact_name: "",
    //       // minimu_miles: "",
    //       contact_email: "",
    //       contact_phone: "",
    //       date_available: "",
    //       // full_truck_load: true,
    //       // origin_latitude: null,
    //       // datetime_created: "",
    //       destination_city: "",
    //       // origin_longitude: null,
    //       // truck_posting_id: "",
    //       equipment_options: "",
    //       origin_country_code: "",
    //       // carrier_country_code: "",
    //       // destination_latitude: null,
    //       // day_of_week_available: 1,
    //       // destination_longitude: null,
    //       equipment_description: "",
    //       // pickup_range_in_miles: null,
    //       // delivery_range_in_miles: null,
    //       destination_country_code: "",
    //       origin_state_province_code: "",
    //       // carrier_state_province_code: "",
    //       destination_state_province_code: ""
    //     };
    //   state.selectedEmail.trips.push(newTrip);
    // },
    addTrip: (state) => {
      state.newTruckPosting = null;
      let newTrip = null;
      if (state.selectedEmail.trips.length > 0) {
        const lastTripIndex = state.selectedEmail.trips.length - 1;
        const lastTrip = state.selectedEmail.trips[lastTripIndex];
        newTrip = { ...cloneDeep(lastTrip), id: uuidv4() };
      } else
        newTrip = {
          data_source: "Internal",
          full_truck_load: true,
          number_available: 1,
          posting_request_id: state.selectedEmail?.id,
          revenova_posting_id: null
        };
      delete newTrip.id;
      state.newTruckPosting = newTrip;
    },
    /* updateTrip(state, { key, tripIndex, value }) {
      const trip = state.selectedEmail.trips[tripIndex];
      const newTrip = { ...trip, [key]: value };
      state.selectedEmail.trips.splice(tripIndex, 1, newTrip);
    }, */
    deleteTrip: (state, id) => {
      state.selectedEmail.trips = state.selectedEmail.trips.filter(
        (trip) => trip.id !== id
      );
    },
    setTripBy: (state, { entity, id, value }) => {
      const index = state.selectedEmail.trips.findIndex(
        (trip) => trip.id === id
      );
      state.selectedEmail.trips[index][entity] = value;
    },
    // COMMODITY MUTATIONS
    addRowToCommodity: (state, id) => {
      state.selectedEmail.trips.forEach((trip) => {
        if (trip.id === id)
          trip.commodity.push({
            hu_count: "",
            dimensions: "",
            weight: "",
            contribution: "",
            nmfc_calculated: "",
            nmfc: "",
            description: ""
          });
      });
    },
    removeRowFromCommodity: (state, { index }) => {
      state.selectedEmail.trips.splice(index, 1);
    },
    // CLIENT EMAILS MUTATIONS
    setClientEmails: function(state, clientEmails) {
      state.selectedEmail.clientEmails = [...new Set(clientEmails)];
    },
    deleteClientEmail: function(state, index) {
      state.selectedEmail.clientEmails.splice(index, 1);
    },
    setEmailTemplate: function(state, emailTemplate) {
      state.selectedEmail.emailTemplate = emailTemplate;
    },
    setEmailContent: function(state, emailContent) {
      state.selectedEmail.emailContent = emailContent;
    },
    ADD_IMAGE(state, img) {
      state.images.push(img);
    },
    SET_IMAGES(state, img) {
      state.images = img;
    }
  }
};
