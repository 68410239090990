"use strict";

import Vue from "vue";
import App from "./App.vue";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store";

// Bootstrap Framework
import "bootstrap/dist/css/bootstrap.min.css";

// Bootstrap Vue Framework
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

//PrimeVue
import "./plugins/primevue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Global Filters
import "./filters/filters";

// Fontawesome Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faTrash,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faListUl,
  faSpinner,
  faTimesCircle,
  faPaperPlane,
  faExclamation,
  faPlus,
  faMinus,
  faSearch,
  faArrowLeft,
  faExclamationTriangle,
  faBackspace,
  faTimes,
  faEye,
  faUser,
  faChartBar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faHome,
  faTrash,
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faListUl,
  faSpinner,
  faTimesCircle,
  faPaperPlane,
  faExclamation,
  faExclamationTriangle,
  faBackspace,
  faTimes,
  faPlus,
  faMinus,
  faSearch,
  faArrowLeft,
  faEye,
  faUser,
  faChartBar
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App)
}).$mount("#app");
