import API from "./api";

export default {
  get: (resource, token) => API(token).get(resource),
  getWithParams: (resource, params = null, token) =>
    API(token).get(resource, { params }),
  post: (resource, payload, token) => API(token).post(resource, payload),
  postWithoutPayload: (resource, token) => API(token).post(resource),
  put: (resource, payload, token) => API(token).put(resource, payload),
  delete: (resource, token) => API(token).delete(resource),
  patch: (resource, payload, token) => API(token).patch(resource, payload)
};
