"use strict";

import * as api from "@/api/services";

export default {
  namespaced: true,
  state: {
    postedEmails: null,
    postedEmailsCount: 0
  },
  getters: {
    postedEmails(state) {
      return state.postedEmails;
    }
  },
  actions: {
    getPostedEmails({ commit }, params) {
      return api.getPostedEmails(params).then(function(res) {
        if (!res) throw new Error("Could not get trip count from server");
        if (!res.data) throw new Error("Could not get trip count from server");
        res.data?.data.forEach(function(email) {
          email.received_at = new Date(email.received_at).toLocaleString();
        });
        commit("SET_POSTED_EMAILS", res.data?.data);
        return res;
      });
    }
  },
  mutations: {
    SET_POSTED_EMAILS(state, emails) {
      state.postedEmails = emails;
    }
  }
};
