export const generalItems = [
  // {
  //   url: "/",
  //   icon: "home",
  //   title: "Home"
  // },
  {
    url: "/ltl/ner",
    icon: "list-ul",
    title: "Available Trucks"
  },
  {
    url: "/ltl/sent",
    icon: "paper-plane",
    title: "Trucks Posted"
  },
  {
    url: "/statistics",
    icon: "chart-bar",
    title: "Statistics"
  }
  // {
  //   url: "/ltl/inProcess",
  //   icon: "spinner",
  //   title: "LTL In Process"
  // },
  // {
  //   url: "/ltl/nonLtl",
  //   icon: "exclamation-triangle",
  //   title: "Non LTL"
  // },
  // {
  //   url: "/ltl/deleted",
  //   icon: "backspace",
  //   title: "Deleted Emails"
  // }
];

// export const superItems = [
//   {
//     url: "/users/manage",
//     icon: "user",
//     title: "Manage Users"
//   }
// ];

// export const userItems = [
//   {
//     url: "/ltl/ner/me",
//     icon: "list-ul",
//     title: "My LTL Queue"
//   },
//   {
//     url: "/ltl/failed/me",
//     icon: "times-circle",
//     title: "My Failed LTL"
//   }
// ];
