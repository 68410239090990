"use strict";

import * as api from "@/api/services";

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem("truck-capacity-token") || "",
    auth: false,
    username: "",
    userStatus: "",
    userStatusColor: ""
  },
  getters: {
    isAuth: function(state) {
      return state.token || null;
    },
    getUsername: function(state) {
      return state.username;
    },
    userStatusColor: (state) => {
      return state.userStatusColor;
    }
  },
  actions: {
    authRequest: function({ dispatch }, authData) {
      return new Promise(function(resolve, reject) {
        api
          .login(authData)
          .then(async function(res) {
            const token = res.data.access_token;
            localStorage.setItem("truck-capacity-token", token);
            const username = authData.get("username");
            await dispatch("loginUser", username);
            resolve();
          })
          .catch(function(err) {
            dispatch("logoutUser");
            reject(err);
          });
      });
    },
    loginUser: async function({ state, commit }) {
      if (!state.auth) {
        commit("loginUser");
      }
    },
    logoutUser: ({ commit }) => {
      commit("logoutUser");
    }
  },
  mutations: {
    loginUser: function(state) {
      state.token = localStorage.getItem("truck-capacity-token");
      state.auth = true;
    },
    logoutUser: function(state) {
      state.token = "";
      state.auth = false;
      state.username = "";
      localStorage.removeItem("truck-capacity-token");
    }
  }
};
