"use strict";

import Vue from "vue";
import VueRouter from "vue-router";
// import jwtDecode from "jwt-decode";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  hashbang: false,
  routes: [
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/LoginPage.vue"),
      props: true
    },
    // {
    //   path: "/",
    //   name: "home",
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "home" */ "../views/HomePage.vue")
    // },
    {
      path: "/",
      redirect: { name: "generalEmailList", params: { type: "ner" } }
    },
    {
      path: "/ltl/:type",
      name: "generalEmailList",
      meta: {
        requiresAuth: true
      },
      props: function(route) {
        const type = route.params.type;
        return { type, isGeneral: true };
      },
      component: () =>
        import(/* webpackChunkName: "ner" */ "../views/EmailList/EmailList.vue")
    },
    {
      path: "/ltl/:type/me",
      name: "emailList",
      meta: {
        requiresAuth: true
      },
      props: function(route) {
        const type = route.params.type;
        return { type, isGeneral: false };
      },
      component: () =>
        import(/* webpackChunkName: "ner" */ "../views/EmailList/EmailList.vue")
    },
    {
      path: "/details/:type/:id",
      name: "generalDetails",
      meta: {
        requiresAuth: true
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "details" */ "../views/EmailDetails.vue")
    },
    {
      path: "/details/:type/me/:id",
      name: "details",
      meta: {
        requiresAuth: true
      },
      props: function(route) {
        const id = route.params.id;
        const type = route.params.type;
        return { id, type, isGeneral: false };
      },
      component: () =>
        import(/* webpackChunkName: "details" */ "../views/EmailDetails.vue")
    },
    {
      path: "/email/:id",
      name: "email",
      meta: {
        requiresAuth: true
      },
      props: true,
      component: () =>
        import(/* webpackChunkName: "details" */ "../views/EmailDetails.vue")
    },
    {
      path: "/statistics",
      name: "statistics",
      meta: {
        requiresAuth: true
      },
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "statistics" */ "../views/StatisticsView.vue"
        )
    },
    {
      path: "*",
      name: "notFound",
      props: (route) => ({ redirect: route.query.redirect }),
      component: () =>
        import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue")
    }
  ]
});

router.beforeEach(async function(to, from, next) {
  const auth = store.getters["user/isAuth"];

  // Authentication
  if (to.matched.some((record) => record.meta.requiresAuth && !auth)) {
    return next({ name: "login", params: { noAuth: true } });
  }
  next();
});

export default router;
