// Primevue Vue Framework
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import Tooltip from "primevue/tooltip";

import Vue from "vue";

Vue.directive("tooltip", Tooltip);

Vue.use(PrimeVue);
